import React, { useState } from 'react';
import Section from '../../molecules/Section/Section';
import Slider from '../../molecules/Slider/Slider';
import { getYoutubeId } from '../../utils/getYoutubeId';
import VideoModal from '../VideoModal/VideoModal';
import VideoSlide from './components/VideoSlide';
import {
  Header,
  SliderWrapper,
  Wrapper,
  innerSectionStyles,
  sectionStyles,
  sliderStyles,
} from './styles/VideoCarousel.styled';
import { getTag } from '../../utils/getTag';

export const slideConfig = {
  dots: false,
  arrows: false,
  controls: true,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  touchThreshold: 100,
  useTransform: false,
};

const VideoCarousel = ({
  component: {
    heading,
    headingKicker,
    headingKickerTag,
    headingTag,
    id,
    subhead,
    videoSlides,
  },
}) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [activeVideoData, setActiveVideoData] = useState({});
  const Heading = headingTag ? getTag(headingTag) : 'h2';
  const Kicker = headingKickerTag ? getTag(headingKickerTag) : 'p';

  return (
    <Section
      id={id}
      style={sectionStyles(isVideoModalOpen)}
      innerStyle={innerSectionStyles}
    >
      <VideoModal
        data={activeVideoData}
        videoId={getYoutubeId(activeVideoData?.youtubeUrl)}
        autoplay
        trigger={isVideoModalOpen}
        setTrigger={setIsVideoModalOpen}
      />
      <Header>
        {headingKicker && (
          <Kicker className="heading-kicker">{headingKicker}</Kicker>
        )}
        {heading && <Heading className="heading">{heading}</Heading>}
        {subhead && <p className="subhead">{subhead}</p>}
      </Header>
      <Wrapper>
        <SliderWrapper>
          <Slider styles={sliderStyles} config={slideConfig}>
            {videoSlides?.map(slide => (
              <VideoSlide
                key={slide.id}
                {...slide}
                setIsVideoModalOpen={setIsVideoModalOpen}
                setActiveVideoData={setActiveVideoData}
              />
            ))}
          </Slider>
        </SliderWrapper>
      </Wrapper>
    </Section>
  );
};

export default VideoCarousel;
