import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const sectionStyles = isVideoModalOpen => css`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: ${colors.base.heroSurface};
  z-index: ${isVideoModalOpen ? '2001' : '1'};
`;

export const innerSectionStyles = css`
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  min-height: 800px;

  ${atMinWidth.xl`
    min-height: 534px;
  `}
`;

export const Header = styled.div`
  width: 100%;
  max-width: 770px;
  height: auto;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${atMinWidth.xs`
    margin: 0px auto 32px 0px;
  `}

  .heading-kicker {
    ${font('overline', 'sm', '700')}
    color: ${colors.primary[500]};
  }

  .heading {
    ${font('display', 'md', '700')}
    color: ${colors.base.black};

    ${atMinWidth.sm`
        ${font('display', 'lg', '700')}
    `}

    ${atMinWidth.xl`
        ${font('display', 'xl', '700')}
    `}
  }

  .subhead {
    ${font('text', 'sm', '400')}
    color: ${colors.base.black};

    ${atMinWidth.sm`
        ${font('text', 'lg', '400')}
    `}

    ${atMinWidth.lg`
        ${font('text', 'xl', '400')}
    `}
  }
`;

export const SliderWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  position: relative;
  top: 0px;
  overflow: visible;

  ${atMinWidth.sm`
    position: absolute;
    bottom: 0px;
    left: 0px;
  `}
`;

export const sliderStyles = css`
  position: relative;
  cursor: grab;

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: none;
  }

  .slick-initialized {
    .slick-track {
      visibility: visible;
    }
  }

  .slick-track {
    display: flex;
    transform: none;
    visibility: hidden;

    ${atMinWidth.sm`
      max-width: 100vw;
    `}
  }

  .slick-slide > div {
    height: 100% !important;
  }

  .slick-slide {
    width: 300px;
    height: inherit !important;
    margin-right: 30px;

    ${atMinWidth.sm`
      width: 570px;
    `}
  }

  .slider-controls {
    margin-top: 32px;
  }
`;
