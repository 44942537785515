import styled from 'styled-components';

import { colors, colorTransparency } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import shadow from '../../../styles/atoms/shadows';

export const Slide = styled.div`
  position: relative;
  width: 300px;
  height: 100%;
  ${atMinWidth.sm`
    width: 400px;
  `}
  ${atMinWidth.md`
    width: 375px;
  `}
  ${atMinWidth.xl`
    width: 570px;
  `}

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, ${colorTransparency(
      colors.base.black,
      0.6
    )} 0%, transparent 30%);
    border-radius: 6px;
  }

  &:hover {
    .slide-image::after {
      opacity: 1;
    }
    .slide-body {
      opacity: 1;
      transform: translateY(0px);
    }
    .pseudo-link span svg {
      transform: translateX(10px);
    }
    .pseudo-link span::after {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`;
export const SlideWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Image = styled.div`
  position: relative;
  height: 375px;
  min-height: 375px;
  ${shadow('lg')}
  ${atMinWidth.sm`
    min-height: 500px;
  `}
  ${atMinWidth.lg`
    min-height: 462px;
  `}
  &::after {
    content: '';
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(1, 29, 186, 0.6);
    border-radius: 6px;
    transition: opacity 200ms ease-in-out;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    transform: translateY(0);
  }
  .play-icon {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 22.5px;
    height: 22.5px;
    z-index: 100;
    ${atMinWidth.sm`
        top: 24px;
        right: 24px;
        width: 30px;
        height: 30px;
    `}
  }
`;

export const PesudoLink = styled.div`
  position: absolute;
  bottom: 18px;
  left: 24px;
  z-index: 100;
  ${font('text', 'md', '700')}
  color: ${colors.base.white};
  cursor: pointer;
  ${atMinWidth.sm`
      ${font('text', 'xl', '700')}
    `}
  span {
    position: relative;
    &::after {
      content: '';
      width: 12px;
      height: 2px;
      background: white;
      position: absolute;
      top: 55%;
      right: 0px;
      border-radius: 2px;
      opacity: 0;
      transform: translateX(-10px);
      transition: all 250ms ease-in-out;
    }
    svg {
      margin-left: 8px;
      transition: all 250ms ease-in-out;
    }
  }
`;
export const Logo = styled.div`
  width: 100%;
  max-width: 150px;
  height: 30px;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 100;

  ${atMinWidth.sm`
    max-width: 175px;
  `}

  ${atMinWidth.md`
    max-width: 200px;
  `}

  .gatsby-image-wrapper {
    width: fit-content;
    height: fit-content;
    && img {
      object-fit: contain;
    }
  }
`;
export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  z-index: 100;
  transform: translateY(0px);
  opacity: 1;
  ${atMinWidth.xl`
    height: fit-content;
    position: absolute;
    left: 24px;
    bottom: 75px;
    justify-content: center;
    opacity: 0;
    transform: translateY(30px);
    transition: all 250ms ease-in-out;
  `}
`;
export const Quote = styled.div`
  &, & p {
    ${font('text', 'md', '400')}
    color: ${colors.gray[900]};
    ${atMinWidth.xl`
      ${font('text', 'lg', '400')}
      color: ${colors.base.white};
    `}
  }
`;
export const Author = styled.div`
    display: flex;
    flex-direction: column;
    .name {
        ${font('text', 'md', '700')}
        color: ${colors.gray[900]};
        ${atMinWidth.xl`
          color: ${colors.base.white};
        `}
    }
    .role {
        ${font('text', 'md', '400')}
        color: ${colors.gray[900]};
        ${atMinWidth.xl`
          color: ${colors.base.white};
        `}
    }
`;

export const TextLogo = styled.p`
  color: ${colors.base.white};
  ${font('text', 'lg', '700')}

  ${atMinWidth.md`
    ${font('text', 'xl', '700')}
  `};
`;
