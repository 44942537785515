import React from 'react';

import OptimizedImage from '../../../utils/OptimizedImage';

import useSwipe from '../../../hooks/useSwipe';

import PlayIcon from '../assets/PlayIcon';

import {
  Slide,
  SlideWrapper,
  Image,
  Body,
  Logo,
  Quote,
  Author,
  PesudoLink,
  TextLogo,
} from '../styles/VideoSlide.styled';
import Icon from '../../../styles/atoms/icons';

const VideoSlide = ({
  heading,
  testimonial,
  websiteVideo,
  setIsVideoModalOpen,
  setActiveVideoData,
}) => {
  const { quote, person, logo } = testimonial;
  const { videoThumbnail } = websiteVideo[0];

  const { handleClick, handleMouseDown } = useSwipe();

  return (
    <Slide
      onMouseDown={e => handleMouseDown(e)}
      onClick={e => {
        handleClick(e, null, setIsVideoModalOpen);
        setActiveVideoData(websiteVideo[0]);
      }}
    >
      <SlideWrapper>
        <Image className="slide-image">
          <Logo>
            {logo?.image?.gatsbyImageData ? (
              <OptimizedImage
                image={logo?.image?.gatsbyImageData}
                alt={logo?.image?.alt}
                title={logo?.image?.title}
              />
            ) : (
              <TextLogo>{heading}</TextLogo>
            )}
          </Logo>
          <PlayIcon />
          {videoThumbnail?.image?.gatsbyImageData && (
            <OptimizedImage
              image={videoThumbnail?.image?.gatsbyImageData}
              timage={videoThumbnail?.imageMobile?.gatsbyImageData}
              mimage={videoThumbnail?.imageMobile?.gatsbyImageData}
              alt={videoThumbnail?.image?.alt}
              title={videoThumbnail?.image?.title}
            />
          )}
          <PesudoLink className="pseudo-link">
            Watch video
            <span>
              <Icon id="FiChevronRight" />
            </span>
          </PesudoLink>
        </Image>
        <Body className="slide-body">
          {quote && (
            <Quote
              className="div"
              dangerouslySetInnerHTML={{ __html: quote }}
            />
          )}
          <Author>
            {person?.firstName || person?.lastName ? (
              <span className="name">
                {person?.firstName} {person?.lastName}
              </span>
            ) : null}
            {person?.role && <span className="role">{person?.role}</span>}
          </Author>
        </Body>
      </SlideWrapper>
    </Slide>
  );
};

export default VideoSlide;
