import React from 'react';

const PlayIcon = () => (
  <svg
    className="play-icon"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM45 31.4354C45.5129 31.1393 45.5129 30.3991 45 30.1031L24.2308 18.1119C23.718 17.8159 23.077 18.186 23.077 18.7781L23.077 42.7604C23.077 43.3525 23.718 43.7226 24.2308 43.4265L45 31.4354Z"
      fill="white"
    />
  </svg>
);

export default PlayIcon;
